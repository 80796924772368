<template>
  <v-container class="py-12">
    <v-row>
      <v-col cols="12" lg="3" md="4">
        <v-card class="mx-auto" elevation="5">
          <v-card
              class="pa-3 dashboard-card-inner"
              max-width="100"
          >
            <v-icon color="#fff">mdi-alert</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>New Cases</h5>
            <h2>{{ supportCount.newCase }}</h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <v-card class="mx-auto" elevation="5">
          <v-card
              class="pa-3 dashboard-card-inner"
              max-width="100"
          >
            <v-icon color="#fff">mdi-alert-minus</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Pending Cases</h5>
            <h2>{{ supportCount.pendingCase }}</h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <v-card class="mx-auto" elevation="5">
          <v-card
              class="pa-3 dashboard-card-inner"
              max-width="100"
          >
            <v-icon color="#fff">mdi-alert-plus</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Resolved Cases</h5>
            <h2>{{ supportCount.resolvedCase }}</h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <v-card class="mx-auto" elevation="5">
          <v-card
              class="pa-3 dashboard-card-inner"
              max-width="100"
          >
            <v-icon color="#fff">mdi-alert-remove</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Closed Cases</h5>
            <h2>{{ supportCount.closedCase }}</h2>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Support Center
            <div class="title-vertical-divider"></div>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="rgb(255, 34, 65)" dark rounded class="mb-2" v-bind="attrs" v-on="on">
                  Create Support Ticket
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  Support Details
                </v-card-title>
                <v-card-text>
                  <v-form ref="form" v-model="valid">
                    <v-select
                        v-model="editedItem.category"
                        :items="category"
                        :rules="required"
                        label="Choose Category"
                        dense
                        outlined
                    ></v-select>
                    <v-text-field
                        v-model="editedItem.subject"
                        label="Write Subject in Brief"
                        :rules="required"
                        outlined
                        dense
                    ></v-text-field>
                    <v-textarea
                        v-model="editedItem.description"
                        outlined
                        dense
                        :rules="required"
                        auto-grow
                        label="Write Description in Details"
                        rows="3"
                        row-height="25"
                    ></v-textarea>
                    <v-select
                        v-model="editedItem.type"
                        :items="type"
                        :rules="required"
                        label="Choose Support Type"
                        dense
                        outlined
                    ></v-select>
                    <v-file-input
                        v-model="editedItem.image"
                        truncate-length="20"
                        prepend-icon=""
                        accept="image/*"
                        ref="file"
                        label="Upload Image"
                        outlined
                        dense
                    ></v-file-input>
                    <v-btn @click="store()" color="primary"
                    >Submit
                    </v-btn
                    >
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="getSupport"
              :search="search"
          >
            <template v-slot:item.subject="{ item }">
              <v-dialog :id="'descriptionDialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                    <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                    Subject
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title
                  >Subject Details
                  </v-card-title
                  >
                  <v-card-text class="py-2">
                    <p>{{ item.subject }}</p>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.description="{item}">
              <v-dialog :id="'descriptionDialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                    <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                    Description
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title
                  >Support Description
                  </v-card-title
                  >
                  <v-card-text class="py-2">
                    <p>{{ item.description }}</p>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.support_image="{ item }">
              <v-dialog v-if="getImage(item.image) !=null" :id="'imageDialog' + item.id" width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                    <v-icon size="14" class="pr-2">mdi-eye</v-icon>
                    Image
                  </v-btn>
                </template>


                <v-card>
                  <v-card-title
                  >Support Image
                  </v-card-title
                  >
                  <v-card-text class="py-2">
                    <img :src="getImage(item.image)" width="100%"
                         alt="">
                  </v-card-text>
                </v-card>
              </v-dialog>
              <div v-else>NA</div>
            </template>
            <template v-slot:item.status="{ item }">
              <p>{{ getStatus(item.is_closed, item.is_resolved) }}</p>
            </template>
            <template v-slot:item.created_at="{ item }">
              <p>{{ formatDate(item.created_at) }}</p>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-left align-items-center">
                <div class="my-2">
                  <v-btn v-if="item.is_resolved===0" :v-model="editedItem.is_resolved" value="3" color="primary"
                         class="my-2" small dark
                         @click="updateCase(item,'is_resolved')">
                    Resolve
                  </v-btn>
                  <v-btn v-else color="success" class="my-2" small dark v-model="editedItem.is_resolved"
                         @click="updateCase(item,'is_resolved')">
                    Resolved
                  </v-btn>
                  <v-btn v-if="item.is_closed===0" color="error" small dark @click="updateCase(item,'is_closed')">
                    Close
                  </v-btn>
                  <v-btn v-else color="error" small dark @click="updateCase(item,'is_closed')">
                    Closed
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import moment from 'moment'

export default {
  data() {
    return {
      search: '',
      valid:true,
      closedCase: '0',
      resolvedCase: '0',
      supportCount: {
        closedCase: 0,
        pendingCase: 0,
        resolvedCase: 0,
        newCase: 0,
      },
      newCase: '0',
      dialog:false,
      pendingCase: '0',
      category: ['Withdraw Money', 'Selling Item'],
      type: ['Phone Call', 'Email'],
      editedItem: {},
      headers: [
        {text: "Ticket Id", value: "ticket_id", align: 'start'},
        // {
        // text: 'Type',
        // sortable: false,
        // value: 'type',
        // },
        {
          text: 'Category',
          sortable: false,
          value: 'category',
        },
        {
          text: 'Subject',
          sortable: false,
          value: 'subject',
        },
        {
          text: 'Description',
          sortable: false,
          value: 'description',
        },
        {text: 'Image', value: 'support_image'},
        {text: 'Support Type', value: 'support_type'},
        {text: 'Status', value: 'status'},
        {text: 'Opened Date', value: 'created_at'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      required: [
        v => !!v || 'This Field is required',

      ],
      support: []

    }
  },
  computed: {
    ...mapGetters({
      getAllSupports: "getSupports",
    }),


    getSupport() {
      return this.support.map((item, index) => ({
        ...item,
        ticket_id: index + 1,
        category: item.category,
        subject: item.subject,
        support_type: item.type,
        description: item.description,
        is_closed: item.is_closed,
        is_resolved: item.is_resolved,


        // banner_image:item.banner,
      }));
    },


  },
  methods: {
    ...mapActions(["getSupports", "updateSupport", "storeSupport"]),
    getStatus(is_closed, is_resolved) {
      if (is_resolved === 1 && is_closed === 0)
        return "Resolved";
      if (is_closed === 1)
        return "Closed";
      else
        return "Open";
    },
    formatDate(value) {
      return moment(value).format("MMMM DD YYYY")
    },
    getImage(path) {

      if (path != null)
        return 'http://127.0.0.1:8000/' + 'storage/' + path
      else
        return null;
    },
    updateCase(item, type) {

        let value;
        if (type === "is_resolved") {
          if (item.is_resolved === 0)
            value = 1;
          else
            value = 0;
        }
        if (type === "is_closed") {
          if (item.is_closed === 0)
            value = 1;
          else
            value = 0;
        }


        let temp = this.support.filter(supports => supports.id === item.id)[0];
        let temp1 = temp;
        let data;
        this.editedIndex = this.support.indexOf(temp);
        if (type === "is_closed") {
          data = {'id': item.id, 'is_closed': value};
          temp.is_closed = value;
        } else {
          data = {'id': item.id, 'is_resolved': value};
          temp.is_resolved = value;
        }

        Object.assign(this.support[this.editedIndex], temp);
        this.updateSupport(data).then(response => {
          this.support[this.editedIndex] = temp;
          this.$store.commit("updateSupports", this.support);
          this.$store.commit("setSupportsCount", response.data.supportCount);
          this.supportCount = response.data.supportCount;

        })
            .catch(error => {
              Object.assign(this.support[this.editedIndex], temp1);
              console.error(error);
            });
        this.$nextTick(() => {
          this.editedIndex = -1
        })

    },
    editItem(item) {
      this.editedIndex = this.support.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    store() {
      if (this.$refs.form.validate()) {
        this.editedItem.is_resolved = 0;
        this.editedItem.is_closed = 0;
        // this.support.push(this.editedItem)
        this.storeSupport(this.editedItem).then(response => {
          this.$store.commit("addSupport", response.data.data);
          this.$store.commit("setSupportsCount", response.data.supportCount);
          this.supportCount = response.data.supportCount;
          this.editedItem = {};
          console.log(response.status);
        })
            .catch(error => {
              console.error(error);
            });
        this.dialog = false;
      }

    }

  },
  mounted() {
    if (!this.$store.state.supports.data) {
      this.getSupports().then((response) => {
        this.support = response.data;
        this.supportCount = response.supportCount;

      });
    } else {
      this.support = this.$store.state.supports.data;
      this.supportCount = this.$store.state.supports.supportCount;

    }

  }

}
</script>

<style lang="scss">
.dashboard-card-inner {
  background: rgb(255, 34, 65) !important;
  top: -1.5rem;
  left: 1rem;
  position: absolute;
}

.title-vertical-divider {
  border-left: 2px solid rgba(112, 110, 110, 0.753);
  height: 40px;
  margin: 0 1rem;
}
</style>